export const schoolData = {
    drafrList : [
        {name:'学校名称',text: 'schoolName'},
        {name:'学校域名',text: 'schoolDomain'},
        {name:'创建人',text: 'createUser'},
        {name:'修改人',text: 'updateUser'},
        {name:'创建时间',text: 'createTime'},
    ],
    currentPage:1,
    pageSize: 10,
    total: 0,
    loading: true,
    spreadTable:[],
    tableHeight: 400,
    operation: ['修改','删除'],
    pageType: 1,
    lastState: 1000,
    userInput: undefined,
    showRoleDia: false,
    studentName:'',
    studentUrl:'',
    studentItem:{},
    schoolRoleDia:false,
    schoolName:'',
    schoolUrl:'',
}